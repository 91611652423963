import React from 'react';
import styled from 'styled-components';

const Plans  = ({ scrollToContact }) => {
  const plans = [
    {
      title: 'Lunch Only',
      price: '2100',
      features: ['Daily Lunch', 'Fresh Ingredients', 'Healthy Meals'],
      buttonText: 'Subscribe',
      highlighted: true,
      discount: false,
    },
    {
      title: 'Dinner Only',
      price: '2100',
      features: ['Daily Dinner', 'Homemade Recipes', 'Balanced Nutrition'],
      buttonText: 'Subscribe',
      highlighted: true,
      discount: false,
    },
    {
      title: 'Lunch & Dinner',
      price: '4000',
      features: ['Daily Lunch & Dinner', '200 Discount', 'Premium Ingredients'],
      buttonText: 'Subscribe',
      highlighted: true,
      discount: true
    }
  ];

  return (
    <PlansSection>
      <div className="container">
        <h2>Subscription Plans</h2>
        <div className="plans">
          {plans.map((plan, index) => (
            <div key={index} className={`plan ${plan.highlighted ? 'highlighted' : ''}`}>
              <h3>{plan.title}</h3>
              <p className="price">₹{plan.price}/month</p>
              <ul>
                {plan.features.map((feature, idx) => (
                  <li key={idx}>{feature}</li>
                ))}
              </ul>
              {plan.discount && <p className="discount">Save ₹200!</p>}
              <button onClick={scrollToContact}>{plan.buttonText}</button>
              {/* <button>{plan.buttonText}</button> */}
            </div>
          ))}
        </div>
      </div>
    </PlansSection>
  );
};

const PlansSection = styled.section`
  padding: 80px 0;
  background-color: #fff;

  h2 {
    text-align: center;
    color: #FA2456;
    margin-bottom: 40px;
    font-size: 2.5rem;
  }

  .plans {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    .plan {
      background: #fff;
      padding: 30px;
      border-radius: 10px;
      width: 30%;
      text-align: center;
      box-shadow: 0 2px 10px rgba(0,0,0,0.1);
      position: relative;
      transition: transform 0.3s;

      &:hover {
        transform: translateY(-5px);
      }

      &.highlighted {
        border: 2px solid #fba5a5;
        background: linear-gradient(4deg, rgb(255 255 255 / 89%) 28.5%, #fba5a5 303.75%);
      }

      h3 {
        color: #333;
        margin-bottom: 20px;
        font-size: 1.8rem;
      }

      .price {
        font-size: 2rem;
        color: #FA2456;
        margin-bottom: 20px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin-bottom: 20px;

        li {
          margin-bottom: 10px;
          color: #555;
        }
      }

      .discount {
        color: #FA2456;
        font-weight: bold;
        margin-bottom: 10px;
      }

      button {
      background: linear-gradient(90deg, #FA2456 0%, #FD735A 100%);
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1rem;
        transition: background-color 0.3s;

        &:hover {
          background-color: #FFC107;
        }
      }
    }
  }

  @media (max-width: 768px) {
      padding: 25px 0;
    .plans {
      flex-direction: column;
      align-items: center;

      .plan {
        width: 100%;
      }
    }
        h2 {
   
    margin-bottom: 20px;
    font-size: 1.7rem;
  }
  }
`;

export default Plans;
