import React from 'react';
import Plans from '../../components/Plans/Plans';

const PlansPage = () => {
  return (
    <div>
      <Plans />
    </div>
  );
};

export default PlansPage;
