import React from 'react';
import styled from 'styled-components';

const About = () => {
  return (
    <AboutSection>
      <div className="container">
        <h2>About Adhya Tiffin Service</h2>
        <p>
          At Adhya Tiffin Service, we are dedicated to providing you with nutritious and delicious homemade meals. Our family-run business uses only the freshest ingredients to prepare a variety of dishes tailored to your preferences. Whether you're looking for a wholesome lunch or a satisfying dinner, we've got you covered!
        </p>
      </div>
    </AboutSection>
  );
};

const AboutSection = styled.section`
  padding: 80px 0;
  background-color: #fff;
  text-align: center;

  h2 {

  
    color: #FA2456;
    margin-bottom: 20px;
    font-size: 2.5rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    color: #555;
  }

         @media only screen and (max-width: 600px) {
            padding: 30px 0;
              h2 {
              font-size: 1.6rem;
    line-height: 30px;

}

}
`;

export default About;
