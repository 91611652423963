import React from 'react';
import GoogleForm from '../GoogleForm/GoogleForm';

const Contact = () => {
  return (
    <div>
      <GoogleForm></GoogleForm>
    </div>
  );
};


export default Contact;



