import React from 'react';
import About from '../../components/About/About';

const AboutPage = () => {
  return (
    <div>
      <About />
    </div>
  );
};

export default AboutPage;
