import React from 'react';
import styled from 'styled-components';
// import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <FooterSection>
      <div className="container">
        <div className="footer-content">
          <h3>Adhya Tiffin Service</h3>
          <p>Delivering fresh and healthy meals to your doorstep every day.</p>
          {/* <div className="social-icons">
            <a href="https://www.facebook.com/yourprofile" target="_blank" rel="noopener noreferrer">
                <FaFacebook />
            </a>
            <a href="https://www.twitter.com/yourprofile" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
            </a>
            <a href="https://www.instagram.com/yourprofile" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
            </a>
          </div> */}
        </div>
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} Adhya Tiffin Service. All rights reserved.</p>
        </div>
      </div>
    </FooterSection>
  );
};

const FooterSection = styled.footer`
background: linear-gradient(90deg, #FA2456 0%, #FD735A 100%);
  color: white;
  padding: 40px 0;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-content {
    text-align: center;
    margin-bottom: 20px;

    h3 {
      font-size: 2rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.1rem;
      // margin-bottom: 20px;
    }

    // .social-icons {
    //   display: flex;
    //   gap: 15px;
    //   justify-content: center;

    //   a {
    //     color: white;
    //     font-size: 1.5rem;
    //     transition: color 0.3s;

    //     &:hover {
    //       color: #FFD700;
    //     }
    //   }
    // }
  }

  .footer-bottom {
    text-align: center;

    p {
      font-size: 0.9rem;
    }
  }
 
`;

export default Footer;
