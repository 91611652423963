import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const GoogleForm = () => {
  const [iframeHeight, setIframeHeight] = useState(1300); // Default height

  useEffect(() => {
    const handleFormSubmit = (event) => {
      if (event.origin === "https://docs.google.com") {
        setIframeHeight(iframeHeight * 0.3);
      }
    };
    window.addEventListener('message', handleFormSubmit);
    return () => {
      window.removeEventListener('message', handleFormSubmit);
    };
  }, [iframeHeight]);

  return (
    <FormContainer>
      <h2>Contact Us</h2>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSeL807WLfYLsm5f2XbILrImoCWhcfKUre_hFsEp2ljK5Eq_zg/viewform?embedded=true"
        width="640"
        height={iframeHeight}
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        title="Google Form for Contact Details"
        onLoad={() => {
          window.postMessage('formLoaded', '*');
        }}
      >
        Loading…
      </iframe>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  padding: 20px;
  text-align: center;

  iframe {
    width: 100%;
    max-width: 640px;  // Adjust the width as needed
    height: ${(props) => props.height || '1300px'}; // Adjust the height dynamically
    border: none;
  }

  h2 {
    margin-bottom: 20px;
    color: #ff4784; // Change as needed
  }
`;

export default GoogleForm;
