import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/images/logo.png';

const Header = () => {
  return (
    <Nav>
      <div className="container">
        <Logo>
        <img src={logo} alt="Adhya Tiffin Service Logo" />
        </Logo>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About Us</Link></li>
          {/* <li><Link to="/menu">Menu</Link></li> */}
          <li><Link to="/plans">Plans</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </div>
    </Nav>
  );
};

const Nav = styled.nav`
  background: linear-gradient(90deg, #FA2456 0%, #FD735A 100%);
  padding: 5px 0;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ul {
    list-style: none;
    display: flex;
    gap: 20px;

    li {
      a {
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        transition: color 0.3s;

        &:hover {
          color: #000;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .container {
      flex-wrap: wrap;
    }
    ul{
      gap: 15px;
      li {
        a{
          font-size: 14px;
        }
      }
    }
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
     width: 175px;
img{
max-width: 100%;
}

  span {
    font-size: 1.5rem;
    font-weight: bold;
  }
`;



export default Header;
