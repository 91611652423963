import React, { useRef } from 'react';
import Hero from '../../components/Hero/Hero';
import About from '../../components/About/About';
// import Menu from '../../components/Menu/Menu';
import Plans from '../../components/Plans/Plans';
import Contact from '../../components/Contact/Contact';

const Home = () => {
  const contactRef = useRef(null);

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Hero scrollToContact={scrollToContact} />
      <About />
      {/* <Menu /> */}
      <Plans scrollToContact={scrollToContact} />
      <div ref={contactRef}>
        <Contact />
      </div>
    </>
  );
};

export default Home;
