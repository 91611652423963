import React from 'react';
import styled from 'styled-components';
import dashImage from '../../assets/images/dash.jpg';

const Hero = ({ scrollToContact }) => {
  return (
    <HeroSection className='banner'>
      <div className="container">
        <div className="content">
          <h1>Delicious & Healthy Tiffin Delivered to Your Doorstep</h1>
          <p>Subscribe to Adhya Tiffin Service for fresh homemade meals daily.</p>
          <button onClick={scrollToContact}>Get Started</button>
        </div>
      </div>
    </HeroSection>
  );
};

const HeroSection = styled.section`
  background: url(${dashImage}) center center/cover no-repeat;
  height: 80vh;
  display: flex;
  align-items: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.7);

  .container {
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
  }

  .content {
    max-width: 600px;

    h1 {
      font-size: 3rem;
      margin-bottom: 20px;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 30px;
    }

    button {
      background: linear-gradient(90deg, #FA2456 0%, #FD735A 100%);
      color: #fff;
      padding: 15px 30px;
      border: none;
      border-radius: 5px;
      font-weight: 600;
      cursor: pointer;
      font-size: 1rem;
      transition: background-color 0.3s;

      &:hover {
        background-color: #FFC107;
      }
    }
  }

  /* Media Queries for Responsiveness */
  
  @media (max-width: 768px) {
    height: 45vh;
    
    .content {
      h1 {
        font-size: 2rem;
        line-height: 38px;
      }

      p {
        font-size: 1rem;
      }

      button {
        padding: 8px 20px;
        font-size: 0.9rem;
      }
    }
  }

  
`;
export default Hero;


